import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  apiUrl: string = 'https://api.multinautica.com.br';
  imageUrl: string = 'https://api.multinautica.com.br/';
  hiddenHeader: boolean = false;

  constructor() {}
}
