<footer class="footer">
    <div class="container">
        <div class="contact" id="contato">
            <div class="col">
                <h2 class="contact-title">
                    FALE COM A
                    <span>GENTE</span>
                </h2>
                <p class="contact-description">
                    Entre em contato conosco, conte<br />
                    sobre suas ideias, que iremos<br />
                    ajudar você a idealizá-las.
                </p>
            </div>
            <div class="col">
                <form class="form" #form="ngForm" (ngSubmit)="submit(form)">
                    <div class="form-control">
                        <input type="text" placeholder="Nome" name="nome" [(ngModel)]="_mensagem.nome" #nome="ngModel"
                            required>
                        <div class="form-control__hint" *ngIf="nome.invalid && (nome.touched || nome.dirty)">
                            <i class="fas fa-exclamation-circle"></i>
                        </div>
                    </div>
                    <div class="form-control">
                        <input type="email" placeholder="E-mail" name="email" [(ngModel)]="_mensagem.email"
                            #email="ngModel" [pattern]="_helper.emailRegex" required>
                        <div class="form-control__hint" *ngIf="email.invalid && (email.touched || email.dirty)">
                            <i class="fas fa-exclamation-circle"></i>
                        </div>
                    </div>
                    <div class="form-control">
                        <input type="tel" placeholder="Telefone" [(ngModel)]="_mensagem.telefone" name="telefone"
                            mask="(00) 0000-0000 || (00) 0 0000-0000" #telefone="ngModel" required>
                        <div class="form-control__hint"
                            *ngIf="telefone.invalid && (telefone.touched || telefone.dirty)">
                            <i class="fas fa-exclamation-circle"></i>
                        </div>
                    </div>
                    <div class="form-control">
                        <textarea name="mensagem" placeholder="Mensagem" [(ngModel)]="_mensagem.mensagem"
                            #mensagem="ngModel" required></textarea>
                        <div class="form-control__hint"
                            *ngIf="mensagem.invalid && (mensagem.touched || mensagem.dirty)">
                            <i class="fas fa-exclamation-circle"></i>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col">
                <button class="btn btn-special btn-special--secondary" (click)="submit(form)">
                    <span>Enviar</span>
                </button>
            </div>
        </div>
        <div class="footer-wrapper">
            <div class="footer-map">
                <img src="assets/banner-footer.jpg" alt="Banner Footer">
            </div>
            <div class="footer-content">
                <div class="footer-item half">
                    <h3 class="footer-item__title">
                        Endereço
                    </h3>
                    <p>
                        {{contatoGeral.rua}}, {{contatoGeral.numeroEndereco}}<br />
                        {{contatoGeral.bairro}}<br />
                        {{contatoGeral.cep}}<br />
                        {{contatoGeral.cidade}} - {{contatoGeral.uf}}
                    </p>
                </div>
                <div class="footer-item half">
                    <h3 class="footer-item__title">
                        telefone
                    </h3>
                    <a [href]="'tel:'+contatoGeral.telefone">
                        <ng-container *ngIf="contatoGeral.telefone.length < 11">
                            {{contatoGeral.telefone | mask: '(00) 0000.0000'}}
                        </ng-container>
                        <ng-container *ngIf="contatoGeral.telefone.length == 11">
                            {{contatoGeral.telefone | mask: '(00) 0 0000.0000'}}
                        </ng-container>
                    </a>
                    <a [href]="'https://wa.me/55'+contatoGeral.whatsApp" target="_blank">
                        <ng-container *ngIf="contatoGeral.whatsApp.length < 11">
                            {{contatoGeral.whatsApp | mask: '(00) 0000.0000'}}
                        </ng-container>
                        <ng-container *ngIf="contatoGeral.whatsApp.length == 11">
                            {{contatoGeral.whatsApp | mask: '(00) 0 0000.0000'}}
                        </ng-container>
                    </a>
                </div>
                <div class="footer-item">
                    <h3 class="footer-item__title">
                        e-mail
                    </h3>
                    <a [href]="'mailto:'+contatoGeral.email">
                        {{contatoGeral.email}}
                    </a>
                </div>
                <ul class="footer-social">
                    <li class="footer-social__item" *ngIf="contatoGeral.linkFacebook">
                        <a [href]="contatoGeral.linkFacebook" target="_blank" class="footer-social__link">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li class="footer-social__item" *ngIf="contatoGeral.linkInstagram">
                        <a [href]="contatoGeral.linkInstagram" target="_blank" class="footer-social__link">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li class="footer-social__item">
                        <a [href]="contatoGeral.linkLinkedin ? contatoGeral.linkLinkedin : 'https://www.linkedin.com/company/multinautica'"
                            target="_blank" class="footer-social__link">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                </ul>
                <div class="footer-credits">
                    <p>Aluflex Multináutica - Todos os direitos reservados. 2020.</p>
                    <a href="https://yourway.digital" target="_blank" class="yourway">
                        <img src="assets/yourway.png" alt="YourWay Digital">
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>

<swal [title]="title" [text]="text" [icon]="icon" #swal>
</swal>

<app-whatsapp-button [contatoGeral]="contatoGeral"></app-whatsapp-button>