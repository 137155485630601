import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContatoGeral } from '../models/contato-geral.model';
import { ContatoMensagem } from '../models/contato-mensagem.model';
import { GaleriaFoto } from '../models/galeria-foto.model';
import { GaleriaVideo } from '../models/galeria-video.model';
import { Parceiro } from '../models/parceiro.model';
import { Projeto } from '../models/projeto.model';
import { Sobre } from '../models/sobre.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public api: ApiService
  ) { }

  buscarContatoGeral(): Observable<ContatoGeral> {
    return this.api.get('/site/contatoGeral/buscar');
  }

  buscarSobre(): Observable<Sobre> {
    return this.api.get(`/site/sobre/buscar`);
  }

  inserirMensagem(msg: ContatoMensagem) {
    return this.api.post(`/site/contatoMensagem/inserir`, msg);
  }

  buscarFotos(numeroPagina: number, registroPorPagina: number): Observable<GaleriaFoto[]> {
    return this.api.get(`/site/galeriaFoto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`);
  }

  buscarVideos(numeroPagina: number, registroPorPagina: number): Observable<GaleriaVideo[]> {
    return this.api.get(`/site/galeriaVideo/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`);
  }

  buscarParceiros(numeroPagina: number, registroPorPagina: number): Observable<Parceiro[]> {
    return this.api.get(`/site/parceiro/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`);
  }

  buscarProjetos(numeroPagina: number, registroPorPagina: number): Observable<Projeto[]> {
    return this.api.get(`/site/projeto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`);
  }

  buscarProdutos(numeroPagina: number, registroPorPagina: number, idModeloProduto: number) {
    return this.api.get(`/site/produto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idModeloProduto=${idModeloProduto}`)
  }

  buscarProduto(slug: string) {
    return this.api.get(`/site/produto/buscar?slug=${slug}`);
  }

  buscarModelosProduto(numeroPagina: number, registroPorPagina: number, idTipoProduto: number) {
    return this.api.get(`/site/modeloProduto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idTipoProduto=${idTipoProduto}`);
  }

  buscarModeloProduto(slug: string) {
    return this.api.get(`/site/modeloProduto/buscar?slug=${slug}`);
  }

  buscarTiposProduto(numeroPagina: number, registroPorPagina: number, idLinhaProduto: number) {
    return this.api.get(`/site/tipoProduto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}&idLinhaProduto=${idLinhaProduto}`)
  }

  buscarTipoProduto(slug: string) {
    return this.api.get(`/site/tipoProduto/buscar?slug=${slug}`);
  }

  buscarLinhas(numeroPagina: number, registroPorPagina: number) {
    return this.api.get(`/site/linhaProduto/buscar?numeroPagina=${numeroPagina}&registroPorPagina=${registroPorPagina}`)
  }
}
