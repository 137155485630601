import { Component, OnInit, Input } from '@angular/core';
import { Produto } from 'src/app/models/produto.model';
import { TipoProduto } from 'src/app/models/tipo-produto.model';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'product-large',
  templateUrl: './product-large.component.html',
  styleUrls: ['./product-large.component.scss']
})
export class ProductLargeComponent implements OnInit {

  @Input('tipoProduto') tipoProduto: TipoProduto;

  constructor(
    public global: GlobalService
  ) { }

  ngOnInit(): void {
  }


}
