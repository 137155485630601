import Swiper from 'swiper';
import { Produto } from './produto.model';
import { TipoProduto } from './tipo-produto.model';

export class LinhaProduto {
  id: number;
  nome: string = '';
  descricao: string = '';
  icone: string = '';
  imagem: string = '';
  imagemHome: string = '';
  imagemPequenaHome: string = '';
  videoHome: string = '';
  cor: string = '';
  slug: string = '';
  situacao: string = 'A';
  produtos?: Produto[];
  tiposProdutos?: TipoProduto[] = [];
  swiperRef: Swiper;
  hovered: boolean = false;
  ordem: number = 99;
  [x: string]: any;

  constructor(obj?) {
    Object.assign(this);
  }
}
