import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GaleriaFoto } from 'src/app/models/galeria-foto.model';
import { Parceiro } from 'src/app/models/parceiro.model';
import { Sobre } from 'src/app/models/sobre.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss'],
})
export class SobreComponent implements OnInit {
  sobre: Sobre = new Sobre();
  html: SafeHtml;

  fotos: GaleriaFoto[] = [];
  parceiros: Parceiro[] = [];

  sobreIndex: number = 0;

  sobreThumbs: SwiperOptions = {
    spaceBetween: 16,
    slidesPerView: 2.5,
  };

  sobreConfig: SwiperOptions = {
    autoplay: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.sobre-carousel__next',
      prevEl: '.sobre-carousel__prev',
    },
    thumbs: {
      thumbsContainerClass: 'about-carousel-buttons',
    },
  };

  constructor(
    public _global: GlobalService,
    public _data: DataService,
    public sanitizer: DomSanitizer,
    public global: GlobalService,
    public _helper: HelperService
  ) {}

  ngOnInit(): void {
    this._global.hiddenHeader = true;
    this.buscarSobre();
    this.buscarFotos();
    this.buscarParceiros();
    this._helper.updateTags({
      nome: 'Sobre - Multináutica - Aluflex',
      descricaoSimples:
        'Nenhum vento é favorável para quem não sabe em qual porto quer chegar.',
      imagem: 'https://multinautica.com.br/assets/avatar.png',
    });
  }

  ngOnDestroy() {
    this._global.hiddenHeader = false;
    this._helper.updateTags();
  }

  ngAfterViewInit() {
    // this.refs.forEach((r, index) => {
    //   if (index == 0) {
    //     this.swiperSobreRef = r;
    //   } else if (index == 1) {
    //     this.swiperSobreThumbsRef = r;
    //   }
    // });
  }

  buscarSobre() {
    this._data.buscarSobre().subscribe((res: Sobre) => {
      this.sobre = res;
      this.html = this.sanitizer.bypassSecurityTrustHtml(res.sobre);
    });
  }

  buscarFotos() {
    this._data.buscarFotos(-99, -99).subscribe((res: GaleriaFoto[]) => {
      this.fotos = res;
      // this.swiperSobreRef.update();
      // this.swiperSobreThumbsRef.update();
    });
  }

  buscarParceiros() {
    this._data
      .buscarParceiros(-99, -99)
      .subscribe((res: Parceiro[]) => (this.parceiros = res));
  }
}
