import { Injectable, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly destroy$ = new Subject<void>();
  private readonly isBrowser: boolean;
  private linkCanonical: HTMLLinkElement;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.createCanonicalTag();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createCanonicalTag() {
    // this.linkCanonical = this.dom.createElement('link');
    // this.linkCanonical.setAttribute('rel', 'canonical');
    // this.dom.head.appendChild(this.linkCanonical);
    // this.linkCanonical.setAttribute('href', this.getCanonicalUrl());
  }

  trackCanonicalChanges() {
    if (!this.isBrowser) {
      return;
    }

    this.router.events
      .pipe(
        filter((event): boolean => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd): void => {
        // this.linkCanonical.setAttribute('href', this.getCanonicalUrl());
      });
  }

  private getCanonicalUrl(): string {
    return this.document.location.origin + this.document.location.pathname;
  }
}
