import { Component, Input, OnInit } from '@angular/core';
import { Produto } from 'src/app/models/produto.model';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input('produto') produto: Produto;

  constructor(
    public global: GlobalService
  ) { }

  ngOnInit(): void {
  }

}
