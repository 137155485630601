<section class="produtos">
  <div class="produtos-banner">
    <div class="container">
      <div class="produtos-banner__content">
        <h1 class="produtos-banner__title">
          <span>NOSSOS</span>
          <strong>PRODUTOS</strong>
        </h1>
        <p>Qualidade na produção de acessórios náuticos e agrícolas</p>
      </div>
    </div>
  </div>
  <div
    class="produtos-banner__item produtos-banner__item--one animate__animated animate__fadeInRight wow"
  >
    <img src="assets/produtos-banner01.jpeg" alt="Produtos banner" />
  </div>
  <div
    class="produtos-banner__item produtos-banner__item--two animate__animated animate__fadeInUp wow"
  >
    <img src="assets/produtos-banner02.jpeg" alt="Produtos banner" />
  </div>
  <div class="produtos-content">
    <ng-container *ngFor="let l of linhas; let index = index">
      <div
        class="produtos-section"
        [class.margin250]="index > 0"
        *ngIf="l.tiposProdutos.length"
      >
        <div class="container">
          <div class="produtos-section__title">
            <h2>
              LINHA <span>{{ l.nome.toLocaleUpperCase() }}</span>
            </h2>
            <div class="line"></div>
            <div class="icon">
              <img [src]="_global.imageUrl + l.icone" [alt]="l.nome" />
            </div>
          </div>
          <swiper
            class="produtos-section__carousel"
            [(index)]="l.slideIndex"
            [config]="produtosConfig"
          >
            <ng-template
              swiperSlide
              *ngFor="let p of l.tiposProdutos; let i = index"
            >
              <app-product [produto]="p"></app-product>
            </ng-template>
            <span slot="container-end">
              <div class="slide-arrows slide-arrows__right white">
                <button
                  class="slide-arrows__item slide-arrows__item--left produtos-carousel__left"
                  (click)="prevSlide(l)"
                  [disabled]="l.slideIndex === 0"
                >
                  <img src="assets/icons/caret.svg" alt="Caret Left" />
                </button>
                <button
                  class="slide-arrows__item slide-arrows__item--right produtos-carousel__next"
                  (click)="nextSlide(l)"
                  [disabled]="l.slideIndex === l.tiposProdutos.length - 1"
                >
                  <img src="assets/icons/caret.svg" alt="Caret right" />
                </button>
              </div>
            </span>
          </swiper>
        </div>
      </div>
      <div
        class="produtos-image"
        [class.produtos-image--right]="!isEven(index)"
        [class.produtos-image--left]="isEven(index)"
        [class.w50]="isEven(index)"
        [style.color]="l.cor"
        *ngIf="l.tiposProdutos.length"
      >
        <div class="container" *ngIf="index + 1 == linhas.length">
          <div class="box-dark hidden-mobile"></div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
