export class GaleriaVideo {

    id: number;
    descricao: string = '';
    video: string = '';
    ordem: number = 99;
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}