<header class="header" [class.active]="active">
  <nav class="header-nav">
    <button
      class="hamburger hamburger--squeeze hidden-desktop1280"
      [class.is-active]="menuOpen"
      type="button"
      (click)="menuOpen = !menuOpen"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <ul class="header-menu" [class.active]="menuOpen">
      <li class="header-menu__item">
        <a class="header-menu__link" routerLink="/" (click)="menuOpen = false">
          HOME
        </a>
      </li>
      <li class="header-menu__item">
        <a
          class="header-menu__link"
          routerLink="/sobre"
          (click)="menuOpen = false"
        >
          A MULTINÁUTICA
        </a>
      </li>
      <li class="header-menu__item">
        <a
          class="header-menu__link"
          routerLink="/produtos"
          (click)="menuOpen = false"
        >
          PRODUTOS
        </a>
      </li>
      <li
        class="header-menu__item"
        (click)="scrollTo('videos')"
        *ngIf="global.hiddenHeader"
      >
        <a class="header-menu__link"> VÍDEOS </a>
      </li>
      <li class="header-menu__item" *ngIf="global.hiddenHeader">
        <a class="header-menu__link" (click)="goToVideos()"> VÍDEOS </a>
      </li>
      <li class="header-menu__item" (click)="scrollTo('contato')">
        <a class="header-menu__link"> CONTATO </a>
      </li>
    </ul>
    <div
      class="header-menu-backdrop hidden-desktop1280"
      [class.active]="menuOpen"
      (click)="menuOpen = false"
    ></div>
    <div class="header-segments hidden-mobile" *ngIf="!global.hiddenHeader">
      <a
        class="header-segments__item btn btn-outline"
        [routerLink]="['/inicio', l.slug]"
        routerLinkActive="active"
        *ngFor="let l of linhas"
      >
        {{ l.nome.toLocaleUpperCase() }}
      </a>
    </div>
    <div class="header-segments hidden-desktop" *ngIf="!global.hiddenHeader">
      <a
        class="header-segments__item btn btn-outline"
        [routerLink]="['/inicio', l.slug]"
        routerLinkActive="active"
        *ngFor="let l of linhas"
        [style.color]="l.cor"
      >
        <span>{{ l.nome.toLocaleUpperCase() }}</span>
        <img [src]="global.imageUrl + l.icone" [alt]="l.nome" />
      </a>
    </div>
    <a routerLink="/" title="Multináutica" class="logo">
      <img src="assets/logo.png" alt="Multináutica" />
    </a>
  </nav>
</header>
