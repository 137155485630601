import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
// imports
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgxMaskModule } from 'ngx-mask';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { WhatsappButtonComponent } from './components/whatsapp-button/whatsapp-button.component';
import { ProductLargeComponent } from './components/product-large/product-large.component';
import { ProductComponent } from './components/product/product.component';
import { ModalVideoComponent } from './components/modal-video/modal-video.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { ProdutosDetalheComponent } from './pages/produtos-detalhe/produtos-detalhe.component';
import { SobreComponent } from './pages/sobre/sobre.component';
// swiper
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    WhatsappButtonComponent,
    ProductLargeComponent,
    ProdutosComponent,
    ProductComponent,
    ProdutosDetalheComponent,
    SobreComponent,
    ModalVideoComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SwiperModule,
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
