import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GaleriaFoto } from 'src/app/models/galeria-foto.model';
import { GaleriaVideo } from 'src/app/models/galeria-video.model';
import { LinhaProduto } from 'src/app/models/linha-produto.model';
import { Projeto } from 'src/app/models/projeto.model';
import { Sobre } from 'src/app/models/sobre.model';
import { TipoProduto } from 'src/app/models/tipo-produto.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { VideoService } from 'src/app/services/video.service';
import Swiper, { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  hoverNautica: boolean = false;
  hoverEspeciais: boolean = false;
  hoverAgricola: boolean = false;
  showFeedback: boolean = false;

  sobreIndex: number = 0;
  sobreImages = [
    { img: 'assets/sobre1.png' },
    { img: 'assets/sobre2.png' },
    { img: 'assets/sobre3.png' },
  ];

  sobreThumbsConfig: SwiperOptions = {
    spaceBetween: 16,
    slidesPerView: 2.5,
  };

  sobreConfig: SwiperOptions = {
    autoplay: false,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.sobre-carousel__next',
      prevEl: '.sobre-carousel__prev',
    },
    thumbs: {
      thumbsContainerClass: 'about-carousel-buttons',
    },
  };

  produtosConfig: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.produtos-carousel__next',
      prevEl: '.produtos-carousel__prev',
    },
    breakpoints: {
      0: {
        spaceBetween: 0,
      },
      768: {
        spaceBetween: 60,
      },
    },
  };

  videosConfig: SwiperOptions = {
    breakpoints: {
      0: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      768: {
        spaceBetween: 50,
        slidesPerView: 3,
      },
    },
  };

  // @ViewChildren(SwiperDirective) refs: QueryList<SwiperDirective>;

  linhas: LinhaProduto[] = [];
  fotos: GaleriaFoto[] = [];
  videoSelected: GaleriaVideo = new GaleriaVideo();
  videos: GaleriaVideo[] = [];
  projetos: Projeto[] = [];
  tiposProduto: TipoProduto[] = [];
  linha: LinhaProduto = new LinhaProduto();
  sobre: SafeHtml;
  proximaLinha: LinhaProduto = new LinhaProduto();

  constructor(
    public _video: VideoService,
    public _data: DataService,
    public _helper: HelperService,
    public global: GlobalService,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.route.queryParams.subscribe((query) => {
      setTimeout(() => this.scrollTo(query.section), 300);
    });
    this.buscarSobre();
    this.buscarProjetos();
    this.buscarFotos();
    this.buscarVideos();
    this.route.params.subscribe((param) =>
      this.buscarLinhas(param.linha ? param.linha : 'nautica')
    );
  }

  buscarLinhas(linha: string) {
    this._data.buscarLinhas(-99, -99).subscribe((res: LinhaProduto[]) => {
      const index = res.findIndex((l) => l.slug == linha);
      this.linhas = res.map((l) => {
        l.hovered = false;
        return l;
      });
      this.linha = res[index];
      this.buscarTiposProdutos(this.linha.id);
      const proximaLinha = res[index + 1];
      this.proximaLinha = proximaLinha ? proximaLinha : res[0];
    });
  }

  buscarFotos() {
    this._data
      .buscarFotos(-99, -99)
      .subscribe((res: GaleriaFoto[]) => (this.fotos = res));
  }

  buscarSobre() {
    this._data
      .buscarSobre()
      .subscribe(
        (res: Sobre) =>
          (this.sobre = this.sanitizer.bypassSecurityTrustHtml(res.sobre))
      );
  }

  buscarProjetos() {
    this._data.buscarProjetos(1, 6).subscribe((res: Projeto[]) => {
      this.projetos = res.map((projeto, index) => {
        projeto.active = index == 0 ? true : false;
        return projeto;
      });
    });
  }

  toggleProjeto(projeto: Projeto) {
    const index = this.projetos.indexOf(projeto);
    this.projetos = this.projetos.map((p, i) => {
      if (i == index) {
        if (!p.active) {
          p.active = !p.active;
        }
      } else {
        p.active = false;
      }
      return p;
    });
  }

  buscarTiposProdutos(idLinhaProduto) {
    this._data
      .buscarTiposProduto(1, 4, idLinhaProduto)
      .subscribe((res: TipoProduto[]) => (this.tiposProduto = res));
  }

  buscarVideos() {
    this._data.buscarVideos(-99, -99).subscribe((res: GaleriaVideo[]) => {
      if (!res.length) return;

      this.videos = res.map((video) => {
        video.videoId = this._helper.getYoutubeId(video.video);
        video.link = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://www.youtube.com/embed/+${video.videoId}`
        );
        return video;
      });
      this.videoSelected = this.videos[0];
    });
  }

  scrollTo(target: string) {
    const el = document.getElementById(target);
    if (el) {
      el.scrollTo({ behavior: 'smooth', top: -120 });
    }
  }

  onSlideChange(e) {
    console.log(e);
    console.log(e.realIndex);
    this.sobreIndex = e.realIndex;
  }
}
