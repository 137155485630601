import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LinhaProduto } from 'src/app/models/linha-produto.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  active: boolean = false;
  menuOpen: boolean = false;

  linhas: LinhaProduto[] = [];

  @HostListener('window:scroll', [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 50) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  constructor(
    public global: GlobalService,
    public _data: DataService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.buscarLinhas();
  }

  buscarLinhas() {
    this._data
      .buscarLinhas(-99, -99)
      .subscribe((res: LinhaProduto[]) => (this.linhas = res));
  }

  goToVideos() {
    this.router.navigate(['/inicio', this.linhas[0].slug], {
      queryParams: {
        section: 'videos',
      },
    });
  }

  scrollTo(target: string) {
    const el = document.getElementById(target);
    if (el) {
      el.scrollTo({ behavior: 'smooth', top: -120 });
    }
  }
}
