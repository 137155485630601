import { LinhaProduto } from './linha-produto.model';

export class Produto {

    id: number;
    tipo: string = '';
    modelo: string = '';
    descricao: string = '';
    imagem: string = '';
    imagemAplicada: string = '';
    linha: LinhaProduto = new LinhaProduto();
    situacao: string = 'A';
    ordem: number = 99;

    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}