import { Injectable } from '@angular/core';
import { SafeUrl, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  active: boolean = false;
  url: SafeUrl;

  constructor(
    public sanitizer: DomSanitizer
  ) { }

  present(videoId: string) {
    const link = `//www.youtube.com/embed/${videoId}?controls=0&modestbranding=1&showinfo=0&rel=0&autoplay=1&loop=0&iv_load_policy=3&enablejsapi=1`;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    this.active = true;
  }

  dismiss() {
    this.active = false;
  }
}
