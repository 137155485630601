<section class="about">
  <div class="container">
    <div class="about-wrapper">
      <div class="left">
        <swiper class="about-carousel" [config]="sobreConfig">
          <ng-template swiperSlide *ngFor="let f of fotos; let i = index">
            <div class="about-carousel-item">
              <img [src]="global.imageUrl + f.imagem" [alt]="'foto' + i" />
            </div>
          </ng-template>
        </swiper>
        <swiper class="about-carousel-buttons" [config]="sobreThumbs">
          <ng-template swiperSlide *ngFor="let f of fotos; let i = index">
            <div
              class="about-carousel-button"
              [class.active]="sobreIndex === i"
              *ngFor="let f of fotos; let i = index"
            >
              <img [src]="global.imageUrl + f.imagem" [alt]="'thumbs' + i" />
            </div>
          </ng-template>
          <span slot="container-end">
            <div class="slide-arrows">
              <button
                class="slide-arrows__item slide-arrows__item--left sobre-carousel__prev"
              >
                <img src="assets/icons/caret.svg" alt="Caret Left" />
              </button>
              <button
                class="slide-arrows__item slide-arrows__item--right sobre-carousel__next"
              >
                <img src="assets/icons/caret.svg" alt="Caret right" />
              </button>
            </div>
          </span>
        </swiper>
      </div>
      <div class="right">
        <h2 class="about-title">
          <small>CONHEÇA A</small>
          <span>MULTI</span>
          <strong>NÁUTICA</strong>
        </h2>
        <div class="about-description" [innerHtml]="html"></div>
      </div>
    </div>
  </div>
</section>
<div class="waves"></div>
<section class="partners">
  <div class="container">
    <div class="partners__title">
      <h2>
        <span>NOSSOS</span>
        <strong>PARCEIROS</strong>
      </h2>
      <p class="partners__description">
        A Multináutica entende que o sucesso de qualquer empresa não se faz
        sozinho e que o trabalho em parceria é fundamental para alavancar
        resultados positivos.
      </p>
    </div>
    <div class="partners-list">
      <div class="partners-item" *ngFor="let p of parceiros">
        <ng-container *ngIf="!p.link">
          <img [src]="global.imageUrl + p.imagem" [alt]="p.nome" />
        </ng-container>
        <ng-container *ngIf="p.link">
          <a [href]="p.link" target="_blank">
            <img [src]="global.imageUrl + p.imagem" [alt]="p.nome" />
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</section>
