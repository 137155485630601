import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { LinhaProduto } from 'src/app/models/linha-produto.model';
import { Produto } from 'src/app/models/produto.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss'],
})
export class ProdutosComponent implements OnInit, AfterViewInit {
  produtosConfig: SwiperOptions = {
    navigation: {
      nextEl: '.produtos-carousel__next',
      prevEl: '.produtos-carousel__prev',
    },
    breakpoints: {
      0: {
        spaceBetween: 16,
        slidesPerView: 1,
      },
      768: {
        spaceBetween: 50,
        slidesPerView: 3.5,
      },
    },
  };

  produtosNautica: Produto[] = [];
  produtosAgricola: Produto[] = [];
  linhas: LinhaProduto[] = [];

  constructor(
    public _data: DataService,
    public _helper: HelperService,
    public _global: GlobalService
  ) {
    this._global.hiddenHeader = true;
  }

  ngOnInit(): void {
    this._helper.updateTags({
      nome: 'Produtos - Multináutica - Aluflex',
      descricaoSimples:
        'Nenhum vento é favorável para quem não sabe em qual porto quer chegar.',
      imagem: 'https://multinautica.com.br/assets/avatar.png',
    });
  }

  ngAfterViewInit() {
    this.buscarLinhas();
  }

  buscarLinhas() {
    this._data.buscarLinhas(-99, -99).subscribe((res: LinhaProduto[]) => {
      this.linhas = res.map((linha, i) => {
        linha.slideIndex = 0;
        return linha;
      });
    });
  }

  nextSlide(linha: LinhaProduto) {
    if (linha.slideIndex === linha.tiposProdutos.length - 1) return;
    linha.slideIndex++;
  }

  prevSlide(linha: LinhaProduto) {
    if (linha.slideIndex === 0) return;
    linha.slideIndex--;
  }

  isEven(n: number) {
    if (n & 1) {
      return true;
    } else {
      return false;
    }
  }
}
