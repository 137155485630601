import { Component, HostListener } from '@angular/core';
import { VideoService } from './services/video.service';
import { SeoService } from './services/seo.service';
import { AnalyticsService } from './services/analytics.service';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
]);

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keyPressed = event.key;
    if (keyPressed === 'Escape') {
      if (this._video.active) {
        this._video.dismiss();
      }
    }
  }

  constructor(
    public _video: VideoService,
    private seoService: SeoService,
    private analyticsService: AnalyticsService
  ) {
    this.seoService.trackCanonicalChanges();
    this.analyticsService.trackPageViews();
  }

  ngOnInit() {}
}
