import { GlobalService } from './global.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError, tap, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public http: HttpClient,
    public global: GlobalService
  ) { }

  get(url: string) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    return this.http.get(this.global.apiUrl + url, {
      headers: headers
    }).pipe(
      tap(r => r),
      catchError(this.handleError.bind(this))
    );
  }

  post(url: string, body = {}) {
    return this.http.post(this.global.apiUrl + url, body).pipe(
      map(r => r),
      catchError(this.handleError.bind(this))
    );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
