<section
  class="banner"
  [style.border-color]="linha.cor"
  [style.background-image]="'url(' + global.imageUrl + linha.imagemHome + ')'"
>
  <div class="container">
    <div class="banner-wrapper">
      <div class="banner-title">
        <span class="animate__animated animate__fadeInDown">LINHA</span>
        <div
          class="content animate__animated animate__fadeInDown animate__slow"
        >
          <div class="banner-title__icon" [style.border-color]="linha.cor">
            <img [src]="global.imageUrl + linha.icone" alt="Ícone da linha" />
          </div>
          <div class="banner-title__line" [style.background]="linha.cor"></div>
          <h1 class="banner-title__text" [style.color]="linha.cor">
            {{ linha.nome.toLocaleUpperCase() }}
          </h1>
        </div>
      </div>
      <div class="banner-description animate__animated animate__fadeIn">
        <p>
          {{ linha.descricao }}
        </p>
      </div>
      <a
        class="btn btn-special btn-special--primary"
        (click)="scrollTo('sobre')"
      >
        <span>Saiba mais</span>
      </a>
      <a
        class="banner-video banner-description animate__animated animate__fadeInRight"
        *ngIf="linha.id && linha.videoHome !== ''"
        (click)="_video.present(_helper.getYoutubeId(linha.videoHome))"
      >
        <div class="banner-video-thumbnail">
          <img
            [src]="
              'https://img.youtube.com/vi/' +
              _helper.getYoutubeId(linha.videoHome) +
              '/sddefault.jpg'
            "
            alt="sddefault"
            class="banner-video-thumbnail__image"
          />
          <div class="overlay"></div>
          <img
            src="assets/icons/play.svg"
            alt="Play"
            class="banner-video-thumbnail__icon"
          />
        </div>
      </a>
    </div>
  </div>
  <div class="next-banner">
    <div
      class="next-banner__image"
      [style.background-image]="
        'url(' + global.imageUrl + proximaLinha.imagemPequenaHome + ')'
      "
    ></div>
    <div class="content content-green">
      <span class="next-banner__text" [style.color]="proximaLinha.cor"
        >Linha {{ proximaLinha.nome }}</span
      >
      <div
        class="next-banner__line"
        [style.background-color]="proximaLinha.cor"
      ></div>
      <a
        class="next-banner__icon"
        [style.border-color]="proximaLinha.cor"
        [style.color]="proximaLinha.cor"
        [routerLink]="['/inicio', proximaLinha.slug]"
      >
        <img [src]="global.imageUrl + proximaLinha.icone" alt="agrícola" />
      </a>
    </div>
  </div>
</section>
<section class="categories">
  <div class="container">
    <div class="categories-wrapper" id="sobre">
      <div class="left">
        <h2 class="categories-title animate__animated animate__fadeInLeft wow">
          <b>qualidade</b> e <b>excelência</b> na produção de acessórios
          <b>náuticos</b> e <b>agrícolas</b>
        </h2>
      </div>
      <div class="right" *ngIf="linhas.length > 0">
        <h3 [style.color]="linha.cor">O QUE VOCÊ BUSCA</h3>
        <ul>
          <li *ngFor="let l of linhas">
            <a (mouseover)="l.hovered = true" (mouseout)="l.hovered = false">{{
              l.nome
            }}</a>
          </li>
        </ul>
        <div
          class="categories-item animate__animated animate__fadeIn wow"
          *ngFor="let l of linhas"
          [class.active]="l.hovered"
          [class.categories-item__nautica]="l.nome === 'Náutica'"
          [class.categories-item__agricola]="l.nome === 'Agrícola'"
          [class.categories-item__especial]="l.nome === 'Especial'"
        >
          <span class="blue-line" [style.background-color]="l.cor"></span>
          <span class="blue-square" [style.border-color]="l.cor"></span>
          <img [src]="global.imageUrl + l.imagemPequenaHome" [alt]="l.nome" />
        </div>
      </div>
    </div>
  </div>
</section>
<div class="waves"></div>
<section class="about">
  <div class="container">
    <div class="about-wrapper">
      <div class="left">
        <swiper
          class="about-carousel"
          [config]="sobreConfig"
          [(index)]="sobreIndex"
        >
          <ng-template swiperSlide *ngFor="let f of fotos; let i = index">
            <div class="about-carousel-item">
              <img [src]="global.imageUrl + f.imagem" alt="'sobreGrande' + i" />
            </div>
          </ng-template>

          <span slot="container-end">
            <div class="slide-arrows">
              <button
                class="slide-arrows__item slide-arrows__item--left sobre-carousel__prev"
              >
                <img src="assets/icons/caret.svg" alt="Caret Left" />
              </button>
              <button
                class="slide-arrows__item slide-arrows__item--right sobre-carousel__next"
              >
                <img src="assets/icons/caret.svg" alt="Caret right" />
              </button>
            </div>
          </span>
        </swiper>
        <swiper
          [config]="sobreThumbsConfig"
          class="about-carousel-buttons"
          (slideChange)="onSlideChange($event)"
        >
          <ng-template swiperSlide *ngFor="let f of fotos; let i = index">
            <div class="about-carousel-button" (click)="sobreIndex = i">
              <img [src]="global.imageUrl + f.imagem" alt="'sobreThumbs' + i" />
            </div>
          </ng-template>
        </swiper>
      </div>
      <div class="right">
        <h2 class="about-title">
          <small>CONHEÇA A</small>
          <span>MULTI</span>
          <strong>NÁUTICA</strong>
        </h2>
        <div class="about-description" [innerHtml]="sobre"></div>
        <a class="btn btn-special btn-special--secondary" routerLink="/sobre">
          <span>Saiba mais</span>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="home-produtos" *ngIf="tiposProduto.length">
  <div class="home-produtos-wrapper">
    <div class="home-produtos__title">
      <h2>
        <span>NOSSOS</span>
        <strong>PRODUTOS</strong>
      </h2>
      <p>
        {{ linha.descricao }}
      </p>
    </div>

    <swiper
      class="home-produtos-carousel"
      [config]="produtosConfig"
      (swiper)="produtosSwiper = $event"
    >
      <ng-template swiperSlide *ngFor="let t of tiposProduto">
        <product-large [tipoProduto]="t"></product-large>
      </ng-template>
      <span slot="container-end">
        <div class="slide-arrows">
          <button
            class="slide-arrows__item slide-arrows__item--left produtos-carousel__prev"
            (click)="produtosSwiper.slidePrev()"
          >
            <img src="assets/icons/caret.svg" alt="Caret Left" />
          </button>
          <button
            class="slide-arrows__item slide-arrows__item--right produtos-carousel__next"
            (click)="produtosSwiper.slideNext()"
          >
            <img src="assets/icons/caret.svg" alt="Caret right" />
          </button>
        </div>
      </span>
    </swiper>
  </div>
</section>

<section class="projetos">
  <div class="blue-square hidden-mobile"></div>
  <div class="container">
    <div class="projetos-box">
      <div class="projetos-list">
        <div
          class="projetos-item"
          *ngFor="let p of projetos"
          (click)="toggleProjeto(p)"
          #projectItem
          [style.height]="projectItem.offsetWidth + 'px'"
          [class.active]="p.active"
          (click)="scrollTo('depoimento')"
        >
          <img [src]="global.imageUrl + p.imagem" [alt]="p.nome" />
        </div>
      </div>
      <div class="projetos-content">
        <h2 class="projetos__title">
          <span>CONHEÇA OS</span>
          <strong>PROJETOS</strong>
        </h2>
        <p>
          Confira alguns projetos que entregamos e que tivemos um enorme
          feedback de nossos clientes.
        </p>
        <ng-container *ngFor="let p of projetos">
          <div class="projetos-item__quote" *ngIf="p.active" id="depoimento">
            <!-- <div class="header">
                            <div class="avatar">
                                <span class="avatar-commas">
                                    <img src="assets/icons/comma.png" alt="Aspas">
                                </span>
                                <img [src]="global.imageUrl+p.imagemDepoimento" [alt]="p.nome">
                            </div>
                            <div class="info">
                                <h3>{{p.nomeDepoimento}}</h3>
                                <span>{{p.cargoDepoimento}}</span>
                            </div>
                        </div> -->
            <h3>Sobre o projeto:</h3>
            <p>
              {{ p.descricao }}
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<section class="videos" id="videos" *ngIf="videoSelected.id">
  <div class="videos-background"></div>
  <div class="waves"></div>
  <div class="container">
    <div class="videos-wrapper">
      <div class="videos-content">
        <h2 class="videos__title">
          <span>CONHEÇA A</span>
          <strong>INSTALAÇÃO</strong>
        </h2>
        <p class="videos__description">
          Você já viu como são produzidos e instalados nossos produto? Confira
          alguns vídeos que mostram todo o nosso processo do começo até a
          entrega para o cliente.
        </p>
      </div>
      <div class="videos-player">
        <iframe
          [src]="videoSelected.link"
          height="430"
          frameborder="0"
        ></iframe>
        <div
          class="videos-player__overlay"
          (click)="_video.present(videoSelected.videoId)"
        >
          <img
            [src]="
              'https://img.youtube.com/vi/' +
              videoSelected.videoId +
              '/sddefault.jpg'
            "
            alt="Thumbail"
            class="thumbnail"
          />
          <img src="assets/icons/play.svg" alt="Play" class="icon" />
        </div>
      </div>
    </div>
    <swiper
      class="videos-carousel"
      [config]="videosConfig"
      (swiper)="videosSwiper = $event"
    >
      <ng-template swiperSlide *ngFor="let v of videos; let i = index">
        <div
          class="videos-carousel__item"
          (click)="_video.present(v.videoId)"
          [class.hidden-mobile]="i == 0"
          [class.hidden-desktop]="i == 0"
        >
          <img
            [src]="'https://img.youtube.com/vi/' + v.videoId + '/sddefault.jpg'"
            [alt]="'Thumbail' + i"
            class="thumbnail"
          />
          <img src="assets/icons/play.svg" alt="Play" class="icon" />
        </div>
      </ng-template>
      <span slot="container-end"></span>
    </swiper>
  </div>
</section>
