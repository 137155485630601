import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Produto } from 'src/app/models/produto.model';
import { GlobalService } from 'src/app/services/global.service';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';
import { NgForm } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TipoProduto } from 'src/app/models/tipo-produto.model';
import { ModeloProduto } from 'src/app/models/modelo-produto.model';

@Component({
  selector: 'app-produtos-detalhe',
  templateUrl: './produtos-detalhe.component.html',
  styleUrls: ['./produtos-detalhe.component.scss'],
})
export class ProdutosDetalheComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  produto: Produto = new Produto();
  tipoProduto: TipoProduto = new TipoProduto();
  modelos: ModeloProduto[] = [];
  modelo: ModeloProduto = new ModeloProduto();
  produtos: Produto[] = [];
  loadingProdutos: boolean = false;

  @ViewChild('swal') swal: SwalComponent;

  title: string;
  text: string;
  icon: string;

  loading: boolean = false;
  _mensagem: ContatoMensagem = new ContatoMensagem();

  isFixed: boolean = false;
  selectedIndex: number;
  selectedImage: string = '';

  @HostListener('window:scroll', [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 120) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (this.selectedImage) {
        this.selectedImage = '';
      }
    }
  }

  constructor(
    private location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public _data: DataService,
    public _helper: HelperService,
    public global: GlobalService
  ) {
    this.global.hiddenHeader = true;
  }

  ngOnInit(): void {
    this.route.params.subscribe((param) => this.buscar(param.slug));
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this._helper.updateTags();
  }

  goBack() {
    this.location.back();
  }

  buscar(slug: string) {
    this._data.buscarTipoProduto(slug).subscribe((res: TipoProduto) => {
      this.tipoProduto = res;
      this._helper.updateTags({
        nome: res.nome + ' - Multináutica - Aluflex',
        descricaoSimples: res.descricao,
        imagem: this.global.imageUrl + res.imagem,
      });
      this.buscarModelosProduto(res.id);
    });
  }

  buscarModelosProduto(idTipoProduto) {
    this._data
      .buscarModelosProduto(-99, -99, idTipoProduto)
      .subscribe((res: ModeloProduto[]) => {
        this.modelos = res;
        const queryParams = this.route.snapshot.queryParams;
        if (this.modelos.length) {
          if (queryParams.modelo) {
            const slug = this.modelos.find(
              (modelo) => modelo.slug === queryParams.modelo
            ).slug;
            this.buscarModeloProduto(slug);
          } else {
            this.buscarModeloProduto(this.modelos[0].slug);
          }
        }
      });
  }

  onModeloChange(modelo: ModeloProduto) {
    this.produtos = [];
    this.modelo = modelo;

    this.loadingProdutos = true;
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams: {
          modelo: modelo.slug,
        },
      })
      .toString();
    this.location.go(url);
    this.buscarModeloProduto(modelo.slug);
  }

  buscarModeloProduto(slug: string) {
    this._data.buscarModeloProduto(slug).subscribe(
      (res: ModeloProduto) => {
        this.modelo = res;
        this.buscarProdutos(res.id);
      },
      (e) => (this.loadingProdutos = false)
    );
  }

  buscarProdutos(idModeloProduto: number) {
    this._data.buscarProdutos(-99, -99, idModeloProduto).subscribe(
      (res: Produto[]) => {
        setTimeout(() => {
          this.produtos = res;
          this.loadingProdutos = false;
        }, 500);
        const el = document.getElementById('#modelos');
        if (el) {
          setTimeout(() => el.scrollTo({ top: -120, behavior: 'smooth' }), 600);
        }
      },
      (e) => (this.loadingProdutos = false)
    );
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this._helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this._mensagem.mensagem = `Olá, gostaria de saber mais sobre o produto ${this.produto.tipo} - ${this.produto.modelo}!`;

    this._data.inserirMensagem(this._mensagem).subscribe(
      (res: any) => {
        this.title = 'Enviado';
        this.text =
          'Obrigado pela seu contato! Em breve retornaremos para você.';
        this.icon = 'success';
        f.reset();
      },
      (e) => {
        this.title = 'Oops...';
        this.text = 'Algo de errado aconteceu. Por favor, tente novamente.';
        this.icon = 'error';
        f.reset();
      }
    );
    setTimeout(() => {
      this.swal.fire();
      this.loading = false;
    }, 400);
  }

  // sort(index: number) {
  //   this.selectedIndex = index;
  //   this.produtos = this.orderPipe.transform(this.produtos, `campos[${index}].valor`);
  // }

  openImage(image: string) {
    if (!image) return;
    this.selectedImage = this.global.imageUrl + image;
  }

  scrollTo(target: string) {
    const el = document.getElementById(target);
    if (el) {
      el.scrollTo({ behavior: 'smooth', top: -120 });
    }
  }
}
