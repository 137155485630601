import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

declare const gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private enabled: boolean;

  constructor(private location: Location, private router: Router) {
    this.enabled = false;
  }

  trackPageViews(): void {
    if (this.enabled) {
      this.router.events
        .pipe(filter((event): boolean => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd): void => {
          gtag('send', { hitType: 'pageview', page: this.location.path() });
          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects,
          });
        });
    }
  }
}
