<a class="product-large animate__animated animate__fadeIn wow" [routerLink]="['/produtos', tipoProduto.slug]">
    <div class="image" [style.color]="tipoProduto.linhaProduto.cor">
        <img [src]="global.imageUrl+tipoProduto.imagem" [alt]="tipoProduto.nome">
        <div class="type type-blue" [style.background-color]="tipoProduto.linhaProduto.cor">
            <div class="product-large__icon">
                <img [src]="global.imageUrl+tipoProduto.linhaProduto.icone" [alt]="tipoProduto.nome">
            </div>
        </div>
    </div>
    <div class="content">
        <h2 class="product-large__title">
            {{tipoProduto.tipo}} {{tipoProduto.modelo}}
        </h2>
        <p class="product-large__description">
            {{tipoProduto.descricao}}
        </p>
    </div>
</a>