<section class="produto-interno">
  <div class="container">
    <div
      class="models-select"
      *ngIf="modelos.length"
      [class.is-fixed]="isFixed"
    >
      <span class="hidden-desktop models-select__title"
        >Arraste para o lado</span
      >
      <div class="models-select__list">
        <button
          class="models-select__item"
          *ngFor="let m of modelos"
          (click)="onModeloChange(m)"
          [class.active]="m.id === modelo.id"
        >
          {{ m.nome }}
        </button>
      </div>
    </div>

    <div class="models-loading" *ngIf="loadingProdutos">
      <div class="lds-hourglass"></div>
    </div>

    <div class="produto-interno-wrapper">
      <div class="content">
        <button class="produto-interno-close" routerLink="/produtos">
          <i class="fas fa-times"></i>
        </button>
        <h1 class="produto-interno__title">
          <span [style.color]="tipoProduto.linhaProduto.cor">{{
            tipoProduto.linhaProduto?.nome
          }}</span>
          {{ tipoProduto.nome }}
        </h1>
        <h2 class="produto-interno__description">
          {{ tipoProduto.descricao }}
        </h2>
        <form class="form light" #form="ngForm" (ngSubmit)="submit(form)">
          <p>Preencha os campos abaixo que entraremos em contato com você.</p>
          <div class="form-control">
            <input
              type="text"
              placeholder="Nome"
              name="nome"
              [(ngModel)]="_mensagem.nome"
              #nome="ngModel"
              required
            />
            <div
              class="form-control__hint"
              *ngIf="nome.invalid && (nome.touched || nome.dirty)"
            >
              <i class="fas fa-exclamation-circle"></i>
            </div>
          </div>
          <div class="form-control">
            <input
              type="email"
              placeholder="E-mail"
              name="email"
              [(ngModel)]="_mensagem.email"
              #email="ngModel"
              required
            />
            <div
              class="form-control__hint"
              *ngIf="email.invalid && (email.touched || email.dirty)"
            >
              <i class="fas fa-exclamation-circle"></i>
            </div>
          </div>
          <div class="form-control">
            <input
              type="tel"
              placeholder="Telefone"
              [(ngModel)]="_mensagem.telefone"
              name="telefone"
              mask="(00) 0000-0000 || (00) 0 0000-0000"
              #telefone="ngModel"
              required
            />
            <div
              class="form-control__hint"
              *ngIf="telefone.invalid && (telefone.touched || telefone.dirty)"
            >
              <i class="fas fa-exclamation-circle"></i>
            </div>
          </div>
          <button class="btn btn-special btn-special--primary" type="submit">
            <span>Enviar</span>
          </button>
          <button
            class="btn btn-special btn-special--primary"
            type="button"
            (click)="scrollTo('modelos')"
            *ngIf="modelos.length"
          >
            <span>Ver modelos</span>
          </button>
        </form>
      </div>
      <div class="image" [style.color]="tipoProduto.linhaProduto.cor">
        <div class="produto-interno__image">
          <img
            [src]="
              modelo.imagem
                ? global.imageUrl + modelo.imagem
                : global.imageUrl + tipoProduto.imagem
            "
            [alt]="tipoProduto.nome"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="models" id="modelos">
    <div class="container">
      <div
        class="models-empty"
        *ngIf="!produtos.length && modelos.length && !loadingProdutos"
      >
        <h3>Este modelo não possui produtos.</h3>
      </div>

      <div class="models-loading" *ngIf="loadingProdutos">
        <div class="lds-hourglass"></div>
      </div>
      <div class="models-list" *ngIf="produtos.length">
        <div class="models-header hidden-mobile">
          <div class="col is-image">
            <span>Imagem</span>
          </div>
          <div
            class="col"
            *ngFor="let campo of modelo.fichaTecnica.campos; let i = index"
            [class.is-file]="campo.tipoCampo === 'A'"
          >
            <span>{{ campo.nome }}</span>
          </div>
        </div>
        <div class="models-item" *ngFor="let p of produtos">
          <div class="col is-image">
            <img
              [src]="
                p.imagem ? global.imageUrl + p.imagem : 'assets/avatar.png'
              "
              [alt]="p.nome"
              (click)="openImage(p.imagem)"
            />
            <span class="is-image__label hidden-mobile" *ngIf="p.imagem"
              >clique para expandir</span
            >
          </div>
          <div
            class="col"
            *ngFor="let c of p.campos; let i = index"
            [class.is-file]="c.cadastroCampo.tipoCampo === 'A'"
          >
            <span *ngIf="c.cadastroCampo.tipoCampo !== 'A'">
              <small class="hidden-desktop"
                >{{ modelo.fichaTecnica.campos[i].nome }}:</small
              >
              {{ c.valor }}
            </span>
            <div *ngIf="c.valor">
              <a
                class="models-item__pdf"
                *ngIf="c.cadastroCampo.tipoCampo === 'A'"
                [href]="global.imageUrl + c.valor"
                target="_blank"
              >
                <img src="assets/icons/pdf-file.svg" alt="Pdf File" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-image" [class.active]="selectedImage">
    <div class="modal-image__header">
      <h3>Imagem</h3>
      <button class="modal-image__close" (click)="selectedImage = ''">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <img [src]="selectedImage" alt="Modalll" />
  </div>
  <div
    class="modal-image-backdrop"
    (click)="selectedImage = ''"
    [class.active]="selectedImage"
  ></div>
</section>

<swal [title]="title" [text]="text" [icon]="icon" #swal> </swal>
